import { ErrorClient } from "app/config/services/error-reporting"
import { APIAssignment, decode as decodeAssignment } from "models/Assignment"
import { APIItem, decode as decodeItem } from "models/Item"
import { decode as decodeSignatory, type APISignatory } from "models/Signatory"
import {
  APISigningGroup,
  decode as decodeSigningGroup,
} from "models/SigningGroup"
import { decode as decodeTransaction } from "models/Transaction"
import { APIParticipant, decode as decodeParticipant } from "models/Participant"

import { reduceIntoKeyByValue } from "helpers/array"
import {
  APIDocument,
  Document as TransactionDocument,
  decode as decodeDocument,
} from "models/Document"
import { SignatoryWithRelations } from "models/SignatoryWithRelations"
import {
  APITransactionElement,
  decodeTransactionElement,
} from "models/TransactionElement"

export const selectTransaction = decodeTransaction

export const selectAssignments = (assignments: APIAssignment[] = []) =>
  assignments.map(decodeAssignment)

export const selectDocuments = (
  documents: APIDocument[] = []
): TransactionDocument[] => documents.map(decodeDocument)

export const selectItems = (items: APIItem[]) => items?.map(decodeItem)

export const selectTransactionElements = (elements: APITransactionElement[]) =>
  elements?.map(decodeTransactionElement)

export const selectParticipants = (participants: APIParticipant[]) =>
  participants.map(decodeParticipant)

export const selectSignatories = (
  signatories: APISignatory[] = []
): SignatoryWithRelations[] => {
  try {
    let byId = reduceIntoKeyByValue(signatories.map(decodeSignatory))
    return Object.values(byId).map((sig) => ({
      ...sig,
      representatives: sig.representatives
        .map((rep) => ({
          ...rep,
          signatory: byId[rep.signatoryId],
        }))
        .filter((r) => !!r.signatory), // Finding orphan representative ids?
    }))
  } catch (e) {
    ErrorClient.notify(e, (evt) => {
      evt.addMetadata("signatoriesJSType", signatories)
    })
    return []
  }
}

export const selectSigningGroups = (groups: APISigningGroup[]) =>
  groups.map(decodeSigningGroup)
