import { TextAlignType, type APIBlock } from "../Block"
import { type Content } from "../Content"

export type TextBlock = {
  id: string
  kind: "text" | "lead_in" | "footer" | "witness"
  grid: [1, 1] | [1, 2] | [2, 2]
  content: Content[]
  textAlign?: TextAlignType
}

const isValidBlockKind = (kind: string): kind is TextBlock["kind"] => {
  return (
    kind === "text" ||
    kind === "lead_in" ||
    kind === "footer" ||
    kind === "witness"
  )
}

export const decodeTextBlock = (data: APIBlock): TextBlock => {
  const { id, kind, grid, content, text_align } = data

  return {
    id,
    kind: isValidBlockKind(kind) ? kind : "text", // default to 'text' ?
    grid,
    content: content || [],
    textAlign: text_align || "left",
  }
}

export const encodeTextBlock = (data: TextBlock): APIBlock => {
  const { id, kind, grid, content, textAlign } = data

  return {
    id,
    kind,
    grid,
    content,
    text_align: textAlign || "left",
  }
}
