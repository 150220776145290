import { type Block, type APIBlock, decodeBlock, encodeBlock } from "./Block"

const MARGIN_TYPES = ["regular", "narrow", "moderate", "large"] as const
export type MarginType = (typeof MARGIN_TYPES)[number]

const FONT_TYPES = [
  "arial",
  "calibri",
  "inter",
  "helvetica",
  "open_sans",
  "times_new_roman",
] as const
export type FontType = (typeof FONT_TYPES)[number]

export type APIInstaPageV2 = {
  uuid: string
  for_signing: boolean
  type: "instapagev2"
  margins?: MarginType
  font?: FontType
  font_size?: number
  consolidate_signer: boolean
  blocks?: APIBlock[]
  assignments?: string[]
  // TBD 👇
  // signing_groups: string[]
  signature_page_type: "breakaway" | "counterpart"
}

export type InstaPageV2 = {
  assignments: string[]
  blocks: Block[]
  consolidateSigner: boolean
  font: FontType
  fontSize: number
  forSigning: boolean
  id: string
  margins: MarginType
  number: number
  type: "instapagev2"
  // TBD 👇
  // signingGroups: string[]
  signaturePageType: "breakaway" | "counterpart"
}

export type IPRenderedPage = Omit<InstaPageV2, "assignments">

export const decodeInstaPageV2 = (
  data: APIInstaPageV2,
  index: number
): InstaPageV2 => {
  const {
    uuid,
    type,
    margins = "regular",
    font = "times_new_roman",
    font_size,
    consolidate_signer,
    blocks,
    assignments,
    signature_page_type,
    // TBD 👇
    // signing_groups,
  } = data

  return {
    id: uuid,
    forSigning: true,
    type,
    margins,
    font,
    fontSize: font_size || 12,
    consolidateSigner: consolidate_signer,
    blocks: blocks?.map((block) => decodeBlock(block)) || [],
    assignments: assignments || [],
    signaturePageType: signature_page_type,
    number: index,
    // TBD 👇
    // signingGroups: signing_groups || [],
  }
}

export const encodeInstaPageV2 = (data: InstaPageV2): APIInstaPageV2 => {
  const {
    id,
    margins,
    font,
    consolidateSigner,
    blocks,
    assignments,
    signaturePageType,
    // TBD 👇
    // signing_groups,
  } = data

  return {
    uuid: id,
    for_signing: true,
    type: "instapagev2",
    margins,
    font,
    font_size: data.fontSize || 12,
    consolidate_signer: consolidateSigner,
    blocks: blocks.map((block) => encodeBlock(block)) || [],
    assignments: assignments || [],
    signature_page_type: signaturePageType,
    // TBD 👇
    // signing_groups: signingGroups || [],
  }
}
