import { FileRejection, useDropzone } from "react-dropzone"
import { useAppConfig } from "app/config"
import UploadQueueButton from "./UploadQueueButton"
import UploadQueueDrawer from "./UploadQueueDrawer"
import {
  addRejectedFile,
  MINIMUM_FILE_SIZE,
  buildUploadOperations,
  isPersistable,
} from "./operations"
import { useEffect, useReducer, useState } from "react"
import reducer from "./reducer"
import { useCurrentUser } from "features/auth/withAuth"
import { useUploadQueueState } from "./useUploadQueueState"
import DmsSystem from "app/dms-system/"
import OnClickOutside from "components/util/OnClickOutside"
import UploadQueueContext from "./UploadQueueContext"
import {
  useIsUserOnTransactionIdPage,
  useTransactionIdParam,
} from "helpers/params"
import { usePrevious } from "helpers/hooks"

export default function UploadQueue() {
  const transactionId = useTransactionIdParam()
  const { isUserOnTransactionIdPage } = useIsUserOnTransactionIdPage()
  const { currentUser } = useCurrentUser({ fetchUser: false })
  const {
    drawerIsOpen,
    setDrawerIsOpen,
    isNativeDMSAppOpen,
    setIsNativeDMSAppOpen,
    isPollingForExternalDMS,
    setIsPollingForExternalDMS,
    hasUnsavedChanges,
    setHasUnsavedChanges,
  } = useUploadQueueState()
  let { config } = useAppConfig()
  const [state, dispatch] = useReducer(reducer, {})

  const countOfInProgressUploadsAcrossAllTransactions = Object.values(state)
    .flatMap((transactionId) => transactionId.files)
    .filter((f) => f && !isPersistable(f) && f.status !== "REMOVED").length

  const hasInProgressUploads = !!countOfInProgressUploadsAcrossAllTransactions

  useEffect(() => {
    setHasUnsavedChanges(hasInProgressUploads)
  }, [state])

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = true
  }

  useEffect(() => {
    if (hasUnsavedChanges) {
      addEventListener("beforeunload", handleBeforeUnload)
    }
    return () => removeEventListener("beforeunload", handleBeforeUnload)
  }, [hasUnsavedChanges])

  const onDropAccepted = buildUploadOperations(dispatch, transactionId)

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    rejectedFiles.map((file) => addRejectedFile(dispatch, transactionId)(file))
  }

  const accept = {
    "application/pdf": [], // any PDF file
    "application/msword": [], // just .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [], // just .docx
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    noClick: true,
    minSize: MINIMUM_FILE_SIZE,
  })

  function handleOpenDMS() {
    if (
      currentUser?.dmsType === "IMANAGE" ||
      currentUser?.dmsType === "NET_DOCS"
    ) {
      setIsNativeDMSAppOpen(true)
    } else {
      setIsPollingForExternalDMS(true)
      DmsSystem.open({
        fusionUrl: String(currentUser?.fusion_url),
        transaction_uuid: transactionId,
        user_uuid: currentUser?.uuid,
        upload_type: "new_item",
      })
    }
  }

  const nonRemovedFiles =
    state[transactionId]?.files?.filter((f) => f.status !== "REMOVED") || []
  const previousNumberOfFiles = usePrevious(nonRemovedFiles.length ?? 0)

  useEffect(() => {
    // only run this if on the transaction id page, not items page, create packets, manage signatures, build binders, etc
    if (isUserOnTransactionIdPage) {
      if (
        !!previousNumberOfFiles &&
        previousNumberOfFiles < nonRemovedFiles.length
      ) {
        // open the drawer if a user selected files from either the File Browser or the DMS
        setDrawerIsOpen(true)
      }
    }
  }, [previousNumberOfFiles])

  const [drawerIsClosing, setDrawerIsClosing] = useState(false)
  const prevDrawerOpenValue = usePrevious(drawerIsOpen)
  useEffect(() => {
    if (prevDrawerOpenValue === true) {
      setDrawerIsClosing(true)
      setTimeout(() => {
        setDrawerIsClosing(false)
        // 200 is the duration of the transition in UploadQueueDrawer
      }, 200)
    }
  }, [drawerIsOpen])

  const drawerIsOpenOrClosing = drawerIsOpen || drawerIsClosing

  if (
    currentUser === undefined ||
    !config ||
    !config.SWITCHES?.UPLOAD_QUEUE ||
    window.self !== window.top // prevents the upload queue from rendering in an iframe (i.e. NetDocs modal)
  ) {
    return null
  }

  return (
    <UploadQueueContext.Provider value={{ state, dispatch }}>
      {/* since we are using `fixed`, left is 72 because of the left sidenav plus half the margin of the <main> element */}
      <div
        className={`fixed bottom-4 left-[72px] h-12 ${
          transactionId === undefined || !isUserOnTransactionIdPage
            ? "hidden"
            : ""
        } ${drawerIsOpenOrClosing ? "right-6" : ""}`}
      >
        {/* This is the hidden input that is used to open the file dialog for react-dropzone */}
        <input {...getInputProps()} />

        <UploadQueueButton open={open} handleOpenDMS={handleOpenDMS} />
        <OnClickOutside
          onClickOutside={() => {
            if (!isNativeDMSAppOpen && !isPollingForExternalDMS) {
              setDrawerIsOpen(false)
            }
          }}
        >
          <UploadQueueDrawer
            getRootProps={getRootProps}
            isDragActive={isDragActive}
            open={open}
            handleOpenDMS={handleOpenDMS}
          />
        </OnClickOutside>
      </div>
    </UploadQueueContext.Provider>
  )
}
