import {
  decodeTextBlock,
  encodeTextBlock,
  type TextBlock,
} from "./Blocks/TextBlock"
import {
  type SignatureBlock,
  type AdditionalFieldsType,
  decodeSignatureBlock,
  encodeSignatureBlock,
} from "./Blocks/SignatureBlock"
import { APIBlockStyles } from "./Blocks/BlockStyles"
import { Content } from "./Content"

//               Left   | Full   | Right
export type BlockGrid = [1, 1] | [1, 2] | [2, 2]

const BLOCK_KIND_TYPES = [
  "lead_in",
  "text",
  "signature",
  "footer",
  "spacer",
  "witness",
] as const
export type BlockKindType = (typeof BLOCK_KIND_TYPES)[number]

const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"] as const
export type TextAlignType = (typeof TEXT_ALIGN_TYPES)[number]

export type APIBlock = {
  id: string
  kind: BlockKindType
  grid: BlockGrid
  block_styles?: APIBlockStyles
  children?: APIBlock[]
  content?: Content[] // from WYSIWYG editor - if kind === "text", "lead_in", "footer"
  additional_fields?: AdditionalFieldsType[]
  text_align?: TextAlignType
}

type SpacerBlock = {
  id: string
  kind: "spacer"
  grid: BlockGrid
}
export type Block = SignatureBlock | TextBlock | SpacerBlock

export const decodeBlock = (data: APIBlock): Block => {
  const { kind } = data

  switch (kind) {
    case "text":
    case "lead_in":
    case "footer":
    case "witness":
      return decodeTextBlock(data)
    case "signature":
      return decodeSignatureBlock(data)
    default:
      return {
        id: data.id,
        kind: "spacer",
        grid: data.grid,
      }
  }
}

export const encodeBlock = (data: Block): APIBlock => {
  switch (data.kind) {
    case "text":
    case "lead_in":
    case "footer":
    case "witness":
      return encodeTextBlock(data)
    case "signature":
      return encodeSignatureBlock(data)
    default:
      return {
        id: data.id,
        kind: "spacer",
        grid: data.grid,
      }
  }
}
