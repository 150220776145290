import { removeUndefinedProperties } from "helpers/object"
import { APIItem, Item } from "./Item"
import { APITransaction, Transaction } from "./Transaction"

export type APITransactionElement = {
  item: APIItem["uuid"]
  label: string
  order: number
  parent_element: APITransactionElement["uuid"] | null
  transaction: APITransaction["uuid"]
  uuid: string
}

export type CreatableAPITransactionElement = Omit<APITransactionElement, "uuid">

export type TransactionElement = {
  id: string
  item: Item["id"]
  label: string
  order: number
  parentElement: TransactionElement["id"] | null
  transaction: Transaction["id"]
}

export type CreatableTransactionElement = Omit<TransactionElement, "id">

export type UpdatableTransactionElement = {
  id: string
} & Partial<TransactionElement>

export const decodeTransactionElement = (
  element: APITransactionElement
): TransactionElement => {
  return {
    id: element.uuid,
    item: element.item,
    label: element.label,
    order: element.order,
    parentElement: element.parent_element,
    transaction: element.transaction,
  }
}

export const encodeTransactionElement = (
  element: CreatableTransactionElement | TransactionElement
): CreatableAPITransactionElement | APITransactionElement => {
  return {
    item: element.item,
    label: element.label,
    order: element.order ?? null,
    parent_element: element.parentElement,
    transaction: element.transaction,
    uuid: "id" in element ? element.id : undefined,
  }
}

export const encodeUpdatableTransactionElement = (
  element: UpdatableTransactionElement
): { uuid: string } & Partial<APITransactionElement> => {
  return {
    uuid: element.id,
    ...removeUndefinedProperties({
      item: element.item,
      label: element.label,
      order: element.order,
      parent_element: element.parentElement,
      transaction: element.transaction,
    }),
  }
}
