import Auth from "features/auth"

export async function ApiFetch<T>(
  path: string,
  body?: unknown,
  options: {
    method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE"
  } = {}
) {
  let config = {
    headers: { "content-type": "application/json" },
    ...options,
    body: body ? JSON.stringify(body) : null,
  }

  await Auth.check()

  let response = await fetch("/api" + path, config)

  if (response.ok) {
    // check if response body has data
    if (response.status === 204) {
      return null
    }

    if (response.headers.get("Content-Length") === "0") {
      return null
    }

    let data = (await response.json()) as T

    return data
  }

  if (response.status === 302) {
    // get { url } from json
    return (await response.json()) as T
  }

  if (response.status === 400) {
    // bad data sent
    return Promise.reject({
      status: 400,
      error: true,
      data: await response.json(),
    })
  }

  if (response.status === 401) {
    // unauthenticated
    if (typeof window !== "undefined") {
      if (window.config?.APP_ENV !== "EMBED") {
        await Auth.signOut()
        window.Beacon?.("logout")
        window.location.href = "/login?action=inactive"

        return Promise.reject({
          status: 401,
          error: true,
        })
      }
    }
  }

  return Promise.reject({
    status: response.status,
    error: true,
  })
}

export const ApiHttp = {
  get: <T>(path: string) => ApiFetch<T>(path, null, { method: "GET" }),
  post: <T>(path: string, body: unknown) =>
    ApiFetch<T>(path, body, { method: "POST" }),
  patch: <T>(path: string, body: unknown) =>
    ApiFetch<T>(path, body, { method: "PATCH" }),
  put: <T>(path: string, body: unknown) =>
    ApiFetch<T>(path, body, { method: "PUT" }),
  delete: <T>(path: string) => ApiFetch<T>(path, null, { method: "DELETE" }),
}
