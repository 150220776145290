import {
  APISignatoryCustomFieldValue,
  SignatoryCustomFieldValue,
} from "./InstaPageV2/CustomFields/SignatoryCustomFieldValue"
import { type APIPacket, type Packet, decode as decodePacket } from "./Packet"
import {
  type Representative,
  type OrganizationRelation,
} from "./Representative"

export enum SignatoryTitleOptions {
  ITS = "its",
  TITLE = "title",
  NONE = "",
}

export enum SignatoryFormatOptions {
  NONE = -1,

  // By: Sample Company
  // Its: General Partner
  STYLE1 = 0,

  // By: Sample Company, its General Partner
  STYLE2 = 1,

  // By: Sample Company,
  // its General Partner
  STYLE3 = 2,

  // By: Sample Company
  // Title: General Partner
  STYLE4 = 3,
}

export type SignatoryInstapagesConfig = {
  entity: SignatoryTitleOptions
  individual: SignatoryTitleOptions
  format: SignatoryFormatOptions
}

export enum SignatoryDescriptionOptions {
  STYLE1 = 1,
  STYLE2 = 2,
}

export type APISignatory = {
  address_1: string
  address_2: string
  address: string
  assignments: string[]
  city: string
  email: string
  entity_description: string
  full_name: string
  instapages_config: SignatoryInstapagesConfig | null
  is_org: boolean
  organization_relations: OrganizationRelation[]
  organizations: string[]
  packets?: APIPacket[]
  postal_code: string
  read_only_signing_url?: string
  representatives: string[]
  state: string
  transaction: string
  uuid: string
  custom_values?: APISignatoryCustomFieldValue[]
}

export type Signatory = {
  address_1?: string
  address_2?: string
  address: {
    lineOne: string
    lineTwo: string
    city: string
    state: string
    postalCode: string
  }
  assignments?: string[]
  city?: string
  customValues?: SignatoryCustomFieldValue[]
  description?: string
  email?: string
  entityIds: string[]
  full_name: string
  id: string
  instapagesConfig: SignatoryInstapagesConfig | null
  internationalAddress?: string
  is_org: boolean
  isOrg: boolean
  name: string
  organization_relations: OrganizationRelation[]
  organizations: string[]
  packets?: Packet[]
  postal_code?: string
  readOnlyUrl?: string
  representatives: Representative[]
  state?: string
  title?: string
  transaction: string
  transactionId: string
  uuid: string
}

export type SignatoryWithAuthRep = Signatory & {
  authRep?: SignatoryWithAuthRep
  authReps?: SignatoryWithAuthRep[]
  newItem?: boolean
}

export type SignatoryWithAuthReps = Signatory & {
  authReps?: SignatoryWithAuthReps[]
}

export type CreatableAPISignatory = Pick<
  APISignatory,
  "transaction" | "full_name"
> &
  Pick<
    Partial<APISignatory>,
    | "email"
    | "entity_description"
    | "is_org"
    | "address_1"
    | "address_2"
    | "city"
    | "state"
    | "postal_code"
    | "address"
    | "instapages_config"
    | "custom_values"
  >

export type CreatableSignatory = Pick<Signatory, "transactionId" | "name"> &
  Pick<
    Partial<Signatory>,
    "id" | "description" | "email" | "isOrg" | "address" | "city" | "state"
  > & {
    addressLineOne?: string
    addressLineTwo?: string
    postalCode?: string
    internationalAddress?: string
    customValues?: SignatoryCustomFieldValue[]
  }

export type UpdatableAPISignatory = Pick<APISignatory, "uuid"> &
  Pick<
    Partial<APISignatory>,
    | "transaction"
    | "email"
    | "full_name"
    | "entity_description"
    | "is_org"
    | "address_1"
    | "address_2"
    | "city"
    | "state"
    | "postal_code"
    | "address"
    | "instapages_config"
    | "custom_values"
  >

export type UpdatableSignatory = Required<Pick<Signatory, "id">> &
  Pick<
    Partial<Signatory>,
    | "transactionId"
    | "name"
    | "email"
    | "isOrg"
    | "address"
    | "city"
    | "state"
    | "instapagesConfig"
    | "description"
  > & {
    addressLineOne?: string
    addressLineTwo?: string
    postalCode?: string
    internationalAddress?: string
    customValues?: SignatoryCustomFieldValue[]
  }

const DEFAULT_INSTAPAGES_CONFIG = {
  entity: SignatoryTitleOptions.NONE,
  individual: SignatoryTitleOptions.NONE,
  format: SignatoryFormatOptions.NONE,
}

const hasInstapageConfig = (input: APISignatory["instapages_config"]) =>
  !!input &&
  Object.keys(input || {}).length !== 0 &&
  (input.entity !== DEFAULT_INSTAPAGES_CONFIG.entity ||
    input.individual !== DEFAULT_INSTAPAGES_CONFIG.individual ||
    input.format !== DEFAULT_INSTAPAGES_CONFIG.format)

export const decode = (input: APISignatory): Signatory => ({
  id: input.uuid,
  name: input.full_name,
  description: input.entity_description || "",
  assignments: input.assignments || [],
  email: input.email || "",
  isOrg: input.is_org,
  address: {
    lineOne: input.address_1 || "",
    lineTwo: input.address_2 || "",
    city: input.city || "",
    state: input.state || "",
    postalCode: input.postal_code || "",
  },
  instapagesConfig: hasInstapageConfig(input.instapages_config)
    ? input.instapages_config
    : DEFAULT_INSTAPAGES_CONFIG,
  internationalAddress: input.address,
  entityIds: input.organizations || [],
  representatives: (input.organization_relations || []).map((r) => ({
    ...r,
    signatoryId: r.representative,
  })),
  packets: input.packets?.map((packet) => decodePacket(packet)) || [],
  readOnlyUrl: input.read_only_signing_url,
  transactionId: input.transaction,
  address_1: input.address_1 || "",
  address_2: input.address_2 || "",
  city: input.city || "",
  state: input.state || "",
  postal_code: input.postal_code || "",
  is_org: input.is_org,
  organizations: input.organizations,
  full_name: input.full_name,
  uuid: input.uuid,
  organization_relations: input.organization_relations,
  transaction: input.transaction,
  customValues:
    input.custom_values?.map((customValue) => ({
      id: customValue.uuid,
      signatoryId: customValue.signatory,
      transactionId: customValue.transaction,
      customFieldId: customValue.custom_key,
      value: customValue.value,
    })) || [],
})

export const encodeSignatoryCreate = ({
  transactionId: transaction,
  name: full_name,
  email,
  isOrg: is_org,
  addressLineOne: address_1,
  addressLineTwo: address_2,
  address,
  internationalAddress,
  city,
  state,
  postalCode: postal_code,
  description,
}: CreatableSignatory): CreatableAPISignatory => ({
  address_1: address_1 || address?.lineOne,
  address_2: address_2 || address?.lineTwo,
  address: internationalAddress,
  city: city || address?.city,
  email,
  full_name,
  instapages_config: DEFAULT_INSTAPAGES_CONFIG,
  is_org,
  postal_code: postal_code || address?.postalCode,
  state: state || address?.state,
  transaction,
  entity_description: description || "",
})

export const encodeSignatoryUpdate = ({
  id: uuid,
  transactionId: transaction,
  name: full_name,
  description,
  email,
  isOrg: is_org,
  addressLineOne: address_1,
  addressLineTwo: address_2,
  address,
  instapagesConfig,
  internationalAddress,
  city,
  state,
  postalCode: postal_code,
}: UpdatableSignatory): UpdatableAPISignatory => ({
  uuid,
  transaction,
  full_name,
  entity_description: description || "",
  email,
  is_org,
  instapages_config:
    instapagesConfig && hasInstapageConfig(instapagesConfig)
      ? instapagesConfig
      : DEFAULT_INSTAPAGES_CONFIG,
  address_1: address_1 || address?.lineOne || "",
  address_2: address_2 || address?.lineTwo || "",
  city: city || address?.city || "",
  state: state || address?.state || "",
  postal_code: postal_code || address?.postalCode || "",
  address: internationalAddress || "",
})

export const encodeToSignatory = (input: Record<string, any>): Signatory => ({
  address_1: input.address_1 || "",
  address_2: input.address_2 || "",
  address: input.address,
  city: input.city || "",
  email: input.email || "",
  entityIds: input.entityIds || [],
  full_name: input.full_name,
  id: input.id,
  instapagesConfig:
    input.instapages_config && hasInstapageConfig(input.instapages_config)
      ? input.instapages_config
      : DEFAULT_INSTAPAGES_CONFIG,
  internationalAddress: input.internationalAddress || "",
  is_org: input.is_org,
  isOrg: input.isOrg,
  name: input.name,
  organization_relations: input.organization_relations,
  organizations: input.organizations,
  packets: input.packets || [],
  postal_code: input.postal_code || "",
  readOnlyUrl: input.readOnlyUrl || "",
  representatives: input.representatives || [],
  state: input.state || "",
  transaction: input.transaction,
  transactionId: input.transactionId,
  uuid: input.uuid,
  customValues: input.custom_values || [],
})
